import React, { useContext } from 'react';
import * as S from './CategorySelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { CSSTransition } from 'react-transition-group';

function CategorySelector({ children, otpLabel, subLabel }) {
  const _otpLabel = otpLabel ?? 'One-Time Purchase';
  const _subLabel = subLabel
    ? subLabel.split('|')
    : ['Subscribe and ', 'Save up to 40% + free gift!', ''];

  const otpChild = (children && children[0]) || <></>;
  const subChild = (children && children[1]) || <></>;

  const {
    currentCategory,
    setCurrentCategory,
    setCurrentProduct,
    currentTubIndex,
    setTubIndex
  } = useContext(FunnelContext);

  const isSub = currentCategory === 'subscriptions' ? true : false;

  const handleClick = key => {
    setCurrentCategory(key);
    setTubIndex(-1);
    const currentProduct =
      key === 'subscriptions'
        ? `subscription_${currentTubIndex}`
        : `onetime_${currentTubIndex}`;
    setCurrentProduct(currentProduct);
  };

  const duration = 300,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    };

  return (
    <S.Container>
      <S.CATContainer active={!isSub} onClick={() => handleClick('onetime')}>
        <S.Selector active={!isSub}>
          <S.Dot active={!isSub} />
          <S.Label active={!isSub}>{_otpLabel}</S.Label>
        </S.Selector>
        {!isSub && <S.ChildContainer>{otpChild}</S.ChildContainer>}
      </S.CATContainer>
      <S.CATContainer
        active={isSub}
        onClick={() => handleClick('subscriptions')}
      >
        <S.Selector active={isSub} isSub={true}>
          <S.Dot active={isSub} />
          <S.Label active={isSub}>
            {_subLabel[0] || ''}
            <S.RedText>{_subLabel[1] || ''}</S.RedText>
            {_subLabel[2] || ''}
          </S.Label>
        </S.Selector>
        <CSSTransition
          in={isSub}
          timeout={duration}
          classNames="slide"
          unmountOnExit
          onEnter={onEnter}
          onEntering={onEntering}
          onExit={onExit}
          onExiting={onExiting}
        >
          <S.ChildContainer>{subChild}</S.ChildContainer>
        </CSSTransition>
      </S.CATContainer>
    </S.Container>
  );
}

export default CategorySelector;
